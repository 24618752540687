













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import type { IPermission } from "@/interfaces/IPermission";
import { CHANGE_STUDENT_EDUCATION_LICENSE_CLASS } from "@/constants/GeneralPermissions";

@Component
export default class PermissionTable extends Vue {
  public name = "PermissionTable";

  public fields = [
    { key: "name", label: this.$t("roles.authorization") },
    { key: "actions", label: this.$t("roles.allow_ny") },
  ];

  public forbiddenPermissions = [CHANGE_STUDENT_EDUCATION_LICENSE_CLASS];

  @Prop()
  public data!: Array<IPermission>;

  @Prop()
  public items!: Array<IPermission>;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;

  public selectedResources: Array<IPermission> = this.data;

  public checkIsInclude(item: IPermission): boolean {
    return !!this.data.find((x: IPermission) => x.id === item.id);
  }

  public onChange(item: IPermission): void {
    if (this.disabled) return;
    this.updateSelectionStatus(item);
  }

  public updateSelectionStatus(resource: IPermission): any {
    if (!this.selectedResources.find((x: IPermission) => x.id === resource.id)) {
      return this.selectedResources.push(resource);
    }
    const index = this.selectedResources.findIndex((x: IPermission) => x.id === resource.id);
    if (index > -1) return this.selectedResources.splice(index, 1);
  }

  @Watch("selectedResources", { immediate: true, deep: true })
  public selectedResourcesWatcher(val: Array<IPermission>): void {
    this.$emit("selected-resources", val);
  }
  public get filteredItems() {
    return this.items.filter((permission: any) => !this.forbiddenPermissions.includes(permission.code)) || [];
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100 overflow-y-auto",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":_vm.$t('general.cancel')},on:{"click":function($event){return _vm.onAbort()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.isLoading},on:{"click":function($event){return _vm.onSubmit()}}})],1)]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"form-group flex-0",staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr 1fr","grid-gap":"20px"}},[_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("roles.role"))+" *")]),_c('validation',{attrs:{"validations":_vm.$v.role},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})}}])})],1),_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("roles.description")))]),_c('validation',{attrs:{"validations":_vm.$v.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})}}])})],1),_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("roles.role_type"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.roleType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.roleTypeOption,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.roleType),callback:function ($$v) {_vm.roleType=$$v},expression:"roleType"}})}}])})],1)]),_c('div',{staticClass:"flex-grow-1 overflow-y-auto"},[_c('permission-panel',{attrs:{"data":_vm.data.permissions,"disabled":_vm.hasNotPermissionWrite},on:{"selected-not-module-permissions":_vm.selectedNotModulePermissions,"selected-module-permissions":_vm.selectedModulePermissions}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }